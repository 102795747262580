import React, { useState } from 'react';
import styled from 'styled-components';
import Burger from './Burger';

const AppHeader = styled.header`
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: 5px;
  height: 80px;
  width: 100%;
`;

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <AppHeader>
            <Burger
                open={menuOpen}
                onOpenChanged={() => setMenuOpen(!menuOpen)}
            />
        </AppHeader>
    )
}

export default Header;
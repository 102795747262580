import styled from 'styled-components';
import * as CSS from 'csstype';

interface DisplayFlexProps {
   width?: CSS.Property.Width;
   flexDirection?: CSS.Property.FlexDirection;
   flexWrap?: CSS.Property.FlexWrap;
   justifyContent?: CSS.Property.JustifyContent;
   alignItems?: CSS.Property.AlignItems;
}

export const DisplayFlex = styled.div`
   display: flex;
   flex-direction: ${(props: DisplayFlexProps) => props.flexDirection || 'row'};
   width: ${(props: DisplayFlexProps) => props.width || 'auto'};
   flex-wrap: ${(props: DisplayFlexProps) => props.flexWrap || 'nowrap'};
   justify-content: ${(props: DisplayFlexProps) => props.justifyContent || 'flex-start'};
   align-items: ${(props: DisplayFlexProps) => props.alignItems || 'flex-start'};
`;

export const FlexRow = styled(DisplayFlex)`
   flex-direction: row;
   align-items: ${(props: DisplayFlexProps) => props.alignItems || 'center'};
`;

export const FlexCol = styled(DisplayFlex)`
   flex-direction: column;
`;

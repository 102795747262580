import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { FlexCol } from './components/common/Flex.styled';
import CreateNetwork from './components/CreateNetwork';
import Header from './components/layout/Header';

const styleTheme = {
  primary: "#0606d1",
  secondary: "blue"
};

const StyledApp = styled(FlexCol)`
  background-color: black;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
`;

function App() {
  return (
    <ThemeProvider theme={styleTheme}>
      <StyledApp>
        <Header />
        <CreateNetwork />
      </StyledApp>
    </ThemeProvider>
  );
}

export default App;

import styled from 'styled-components';

export const ButtonBaseStyle = styled.button`
   background: none;
   border: none;
   outline: none;

   color: black;
   background: white;

   padding: 0;
   margin: 0;

   font-size: inherit;
   font-family: inherit;

   cursor: pointer;

   transition: all 0.2s ease-in-out;

   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
`;

export const TextButton = styled(ButtonBaseStyle)`
   color: black;
   background: white;
   border: 1px solid black;
   border-radius: 4px;
   padding: 0.3em 2.5em;

   &:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      background: ${(props) => props.theme.secondary};
      color: white;
   }

   &:disabled {
      border: 1px solid #222;
      background:  #222;
      color: #333;
      cursor: default;
      box-shadow: none;
   }
`;

export const PrimaryButton = styled(TextButton)`
   color: #eee;
   background: ${(props) => props.theme.primary};
   border: 1px solid ${(props) => props.theme.primary};
`;

export const SecondaryButton = styled(TextButton)`
   border: 1px solid ${(props) => props.theme.primary};
`;

export const CircleButton = styled(ButtonBaseStyle)`
   border-radius: 50%;
   height: 2.5em;
   width: 2.5em;
   min-height: 2.5em;
   min-width: 2.5em;

   &:hover {
      background: #eee;
   }

   &:disabled {
      color: #aaa;
      cursor: default;
   }
`;

export const PrimaryCircleButton = styled(CircleButton)`
   &:hover {
      color: white;
      background: ${(props) => props.theme.primary};
   }

   &:hover:disabled {
      color: #aaa;
      background: none;
   }
`;

export const SelectableButton = styled(ButtonBaseStyle)`
   padding: 0.5em 1.5em;
   min-width: 75px;
   border: 1px solid ${(props) => props.theme.primary};
   border-radius: 1.2em;

   &.selected,
   &:hover {
      color: white;
      background: ${(props) => props.theme.primary};
   }

   &:disabled,
   &:hover:disabled {
      color: #aaa;
      background: none;
      border: 1px solid #ccc;
   }
`;

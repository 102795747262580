import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from './common/Buttons.styled';
import { FlexRow } from './common/Flex.styled';

const BaseUrl = styled(FlexRow)`
    font-size: 2rem;
    letter-spacing: 5px;
`;

const NameInput = styled.input`
  font-size: 2rem;
  padding: 0.1em 0.2em;
  margin: 0 0 0 5px;
  letter-spacing: 2px;
  width: 100%;
  border-radius: 2px;
  border: 0;
  letter-spacing: 5px;
`;

const Layout = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-auto-rows: auto;
    grid-gap: 1em;
    padding: 0.5em;
    width: 100%;
    max-width: 600px;
`;

const nameRegex = /^\w+$/;

const CreateNetwork: FC = () => {
    const [name, setName] = useState('');
    const haveName = name.trim().length > 2;
    const isValid = haveName && nameRegex.test(name);

    return (
        <Layout>
            <FlexRow>
                <BaseUrl>allmost.me/</BaseUrl>
                <NameInput
                    value={name}
                    maxLength={15}
                    onChange={(ev) => setName(ev.target.value.trimLeft())}
                />
            </FlexRow>
            <PrimaryButton disabled={!isValid}>Next</PrimaryButton>
        </Layout>
    );
}

export default CreateNetwork;
import styled from 'styled-components';
import React from 'react';

interface BurgerButtonProps {
   open: boolean;
}

const BurgerButton = styled.button`
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   width: 30px;
   height: 30px;
   margin-right: 20px;
   padding: 0;
   background: transparent;
   border: none;
   cursor: pointer;
   z-index: 10000;

   &:focus {
      outline: none;
   }

   @media (min-width: 1200px) {
      display: none;
      width: 0;
      margin: 0;
   }

   div {
      width: 30px;
      height: 2px;
      background-color: #FFF;
      transition: all 0.3s linear;
      transform-origin: 1px;

      :nth-child(1) {
         transform: ${(props: BurgerButtonProps) => (props.open ? 'rotate(45deg)' : 'rotate(0)')};
      }

      :nth-child(2) {
         opacity: ${(props: BurgerButtonProps) => (props.open ? '0' : '1')};
         transform: ${(props: BurgerButtonProps) =>
      props.open ? 'translateX(20px)' : 'translateX(0)'};
      }

      :nth-child(3) {
         transform: ${(props: BurgerButtonProps) => (props.open ? 'rotate(-45deg)' : 'rotate(0)')};
      }
   }
`;

interface BurgerProps {
   open: boolean;
   onOpenChanged: (open: boolean) => void;
}

const Burger = ({ open, onOpenChanged }: BurgerProps) => {
   return (
      <BurgerButton open={open} onClick={() => onOpenChanged(!open)}>
         <div />
         <div />
         <div />
      </BurgerButton>
   );
};

export default Burger;
